import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

let date = new Date();
let year = date.getFullYear();

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey, ich bin <span className="purple">Malte </span>
            aus <span className="purple"> Leer, Niedersachsen.</span>
            <br /> Ich bin Abiturient der 13. Klasse mit Fokus auf Informatik,
            Mathematik & Physik.
            <br />
            Ich arbeite neben der Schule als Tankwart an einer örtlichen
            Tankstelle und bin in der Front-/Backend-Entwicklung für ein
            e-Sports Unternehmen tätig.
            <br />
            <br />
            Coding ist eines meiner Hobbies, neben:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Rudern
            </li>
            <li className="about-activity">
              <ImPointRight /> Motorradfahren
            </li>
            <li className="about-activity">
              <ImPointRight /> Klavier spielen
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Freude an der Arbeit lässt das Werk trefflich geraten"{" "}
          </p>
          <footer className="blockquote-footer">Aristoteles</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
