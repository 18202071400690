import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiOutlineMail, AiFillInstagram } from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Zu meiner <span className="purple"> Person </span>:
            </h1>
            <p className="home-about-body">
              Programmieren hat mich schon seit der sechsten Klasse interessiert
              und ich will nun ein Informatikstudium angehen, um mich zu
              professionalisieren ✔️
              <br />
              <br />
              Programmiert habe ich bereits in
              <i>
                <b className="purple"> Java, Javascript, Python, VFP </b>
              </i>
              sowie auch
              <i>
                <b className="purple"> MySQL</b>
              </i>
              .
              <br />
              <br />
              Ich betreibe eine
              <b className="purple">
                <i> Nextcloud</i>
              </b>
              , einen
              <b className="purple">
                <i> Bitwarden</i>
              </b>
              , einen
              <b className="purple">
                <i> PiHole </i>
              </b>
              sowie eine
              <b className="purple">
                <i> browserbasierte VS-Code-Oberfläche </i>
              </b>
              und einen
              <b className="purple">
                <i> Homebridge-Server </i>
              </b>
              für die Implementierung gewisser Androidgeräte in die
              Apple-Home-App.
              <br />
              <br />
              Ich bilde mich neben dem Abitur und meiner Arbeit in
              Programmiersprachen fort. Zum Beispiel anhand dieser Website
              geschrieben mit
              <i>
                <b className="purple">
                  {" "}
                  modernen Javascript Libraries und Frameworks
                </b>
              </i>
              &nbsp; wie
              <i>
                <b className="purple"> React.js und Next.js</b>
              </i>
              .
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Man findet mich auch dort:</h1>
            <p>
              <span className="purple">Kontaktiere </span> mich gerne!
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="mailto:malte@ehmencloud.de"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/malte_1510"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
